import React, { Component } from 'react'

import { useState } from "react";
import { images, CustomImage } from "./images-rooms-standart-lux.ts";


import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function ImageRoomStandartGridGallery() {
      const [index, setIndex] = useState(-1);
      return (
         <div>
               <PhotoAlbum
                  sizes={{
                       size: "992px",
                       sizes: [
                           { viewport: "(max-width: 767px)", size: "calc(100vw - 32px)" },
                           { viewport: "(max-width: 1279px)", size: "calc(100vw - 288px)" },
                       ],
                   }}
                  breakpoints={[1300]}
                  photos={images}
                  layout="columns"
                  columns="4"
                  onClick={({ index }) => setIndex(index)} />

               <Lightbox
                   slides={images}
                   open={index >= 0}
                   index={index}
                   close={() => setIndex(-1)}
                   plugins={[Fullscreen, Thumbnails]}
               />
          </div>
         )
}