import React, { Component } from 'react';
import ImageHomeGridGallery  from "../components/ImageHomeGridGallery.js";
import GartenGridGallery from "../components/GartenGridGallery.js";
import ImageRoomStandartGridGallery  from "../components/ImageRoomStandartGridGallery.js";
import ImageRoomUlu4wennijStandartGridGallery  from "../components/ImageRoomUlu4wennijStandartGridGallery.js";
import ImageRoomPoluluxStandartGridGallery  from "../components/ImageRoomPoluluxStandartGridGallery.js";
import ImageRoomPolulux4xStandartGridGallery  from "../components/ImageRoomPolulux4xStandartGridGallery.js";
import ImageRoomLux4xStandartGridGallery  from "../components/ImageRoomLux4xStandartGridGallery.js";
import Footer  from "../components/Footer.js";
import TravellinePluginMain from "../components/TravellinePluginMain";

export default class Gallery extends Component {
	render() {
		return (
			<div>
				<div><TravellinePluginMain /></div>
				<div id="imageGallery"><ImageHomeGridGallery /></div>
				<div className="roomName" align="center">Зона отдыха на террасе</div>
				<div id="imageGallery"><GartenGridGallery /></div>
				<div className="roomName" align="center">Двухместный номер Стандарт</div>
				<div id="imageGallery"><ImageRoomStandartGridGallery /></div>
				<div className="roomName" align="center">Четырехместный номер Улучшенный Стандарт</div>
				<div id="imageGallery"><ImageRoomUlu4wennijStandartGridGallery /></div>
				<div className="roomName" align="center">Трёхместный номер Полулюкс</div>
				<div id="imageGallery"><ImageRoomPoluluxStandartGridGallery /></div>
				<div className="roomName" align="center">Четырехместный номер Полулюкс</div>
				<div id="imageGallery"><ImageRoomPolulux4xStandartGridGallery /></div>
				<div className="roomName" align="center">Четырехместный номер Люкс</div>
				<div id="imageGallery"><ImageRoomLux4xStandartGridGallery /></div>
				<div id="footer"><Footer /></div>
			</div>
			)
	}
}