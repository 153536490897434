import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

import Image1 from './assets/gallery/home2.jpg'
import Image2 from './assets/gallery/home3.jpg'
import Image3 from './assets/gallery/home4.jpg'
import Image4 from './assets/gallery/home5.jpg'
import Image5 from './assets/gallery/home6.jpg'
import Image6 from './assets/gallery/home7.jpg'
import Image7 from './assets/gallery/home8.jpg'

export default class CarouselBox extends Component {
	render() {
		return (
			<Carousel>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image1}
						height="900"
						alt="Вид на море"
					/>
					<Carousel.Caption>
						<h3>Вид на море</h3>
						<p> kkkkkkkkkkkkkkkk </p>
					</Carousel.Caption>
				</Carousel.Item>

				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image2}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image3}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image4}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image5}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image6}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src={Image7}
						height="900"
						alt="Вид на море"
					/>
				</Carousel.Item>
			</Carousel>
			)
	}
}