import React, { Component } from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Container, Navbar, Nav} from 'react-bootstrap'
import Home from '../pages/Home.js'

export default class Footer extends Component {
	render() {
		return (
			<div className="contactUs">
					<Navbar expand="md" className="navbar navbar-dark navbar-expand-sm navbar-custom">
						<Container>
							<Container>
								<div align="justify"> Апарт-отель "Южный Кедр" находится в уютном городке Южного Берега Крыма - Алупка. Отель расположен в пешей доступности (10 минут) от галечного пляжа и знаменитого Воронцовского дворца и парка.
								Стильные новые апартаменты оснащены кухнями со столовыми уголками, кроватями с ортопедическими матрасами и гипоаллергенным постельным бельем. Шторы блэкаут не позволят нарушить ваш сон ранним утром. Почти все апартаменты имеют балкон с видом на море или в сад.
								Любителей барбекю ждет беседка с мангалом.
								Для путешествующих на автомобилях на территории отеля имеется парковка.</div>
							</Container>	
						</Container>
					</Navbar>
			</div>
			)
	}
}
