import { Image } from "react-grid-gallery";

import Image1 from './assets/gallery/home1.webp'
import Image2 from './assets/gallery/home2.jpg'
import Image3 from './assets/gallery/home3.jpg'
import Image4 from './assets/gallery/home4.jpg'
import Image5 from './assets/gallery/home5.jpg'
import Image6 from './assets/gallery/home6.jpg'
import Image7 from './assets/gallery/home7.jpg'
import Image8 from './assets/gallery/home8.jpg'
import Image9 from './assets/gallery/home9.jpg'

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: Image1,
    width: 1200,
    height: 800
  },
  {
    src: Image2,
    width: 1200,
    height: 800
  },
  {
    src: Image3,
    width: 1200,
    height: 800
  },
  {
    src: Image4,
    width: 1200,
    height: 800
  },
  {
    src: Image5,
    width: 1200,
    height: 800
  },
  {
    src: Image6,
    width: 800,
    height: 1200
  },
  {
    src: Image7,
    width: 800,
    height: 1200
  },
    {
    src: Image8,
    width: 800,
    height: 1200
  },
  {
    src: Image9,
    width: 800,
    height: 1200
  }
];
