import React, { Component } from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Container, Navbar, Nav} from 'react-bootstrap'

//import { Button } from "rsuite";
  
// Import the default CSS
//import "rsuite/dist/rsuite.min.css";

import Button from '@mui/material/Button';

import logo from './assets/logo_circle.png'
import headerBkg from './assets/header-bkg.jpg'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Home from '../pages/Home.js'
import Contacts from '../pages/Contacts.js'
import Gallery from '../pages/Gallery.js'

export default class Header extends Component {
	render() {
		return (
			<>
				<div className="head-top">
					<Navbar expand="md" className="navbar navbar-dark navbar-expand-sm navbar-custom">
						<Container className="navBarTop">
							<Container>
								<Navbar.Brand href="/">
									<img
										src={logo}
										height="130"
										width="130"
										className="d-inline-block align-top"
										alt="Uzhniy Kedr Logo" />
								</Navbar.Brand>
								<div className="hotelName">ЮЖНЫЙ КЕДР</div>
								<div className="hotelDescription">АПАРТ-ОТЕЛЬ АЛУПКА КРЫМ</div>
							</Container>
							<Navbar.Toggle className="custom-toggler" area-controls="responsive-nav-bar" />
							<Navbar.Collapse id="responsive-nav-bar">
								<Nav className="mr-auto">
									<Nav.Link href="/contacts"> КОНТАКТ </Nav.Link>
									<Nav.Link href="/gallery"> ГАЛЕРЕЯ </Nav.Link>
								</Nav>
							</Navbar.Collapse>	
						</Container>
					</Navbar>

					<Container className="navBarBottom">
				      <Row>
				        <Col sm={8} id="navBarBottomLeft">
				        	<Row>
				        	<div className="app">
				        		СТИЛЬНЫЕ АПАРТАМЕНТЫ У МОРЯ
				        	</div>
				        	</Row>
				        	<Row><p></p></Row>
				        	<Row>
				        	<div className="description"> Апарт-отель "Южный Кедр" находится в уютном городке Южного Берега Крыма - Алупка.<br/>
				        		Отель расположен в пешей доступности (10 минут) от галечного пляжа и знаменитого Воронцовского дворца и парка.
								Стильные новые апартаменты оснащены кухнями со столовыми уголками, кроватями с ортопедическими матрасами и гипоаллергенным постельным бельем. Шторы блэкаут не позволят нарушить ваш сон ранним утром. Почти все апартаменты имеют балкон с видом на море или в сад.
								Любителей барбекю ждет беседка с мангалом.
								Для путешествующих на автомобилях на территории отеля имеется парковка.
							</div>
							</Row>

				        </Col>
				        <Col id="navBarBottomRight"></Col>
				      </Row>
				    </Container>
				</div>

				<Router>
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/contacts" element={<Contacts />} />
						<Route exact path="/gallery" element={<Gallery />} />
					</Routes>
				</Router>
			</>
			)
	}
}