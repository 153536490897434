import { Image } from "react-grid-gallery";

import Image1 from './assets/gallery/rooms/lux4x/lux4x1.jpg'
import Image2 from './assets/gallery/rooms/lux4x/lux4x2.jpg'
import Image3 from './assets/gallery/rooms/lux4x/lux4x3.jpg'
import Image4 from './assets/gallery/rooms/lux4x/lux4x4.jpg'
import Image5 from './assets/gallery/rooms/lux4x/lux4x5.jpg'
import Image6 from './assets/gallery/rooms/lux4x/lux4x6.jpg'
import Image7 from './assets/gallery/rooms/lux4x/lux4x7.jpg'
import Image8 from './assets/gallery/rooms/lux4x/lux4x8.jpg'
import Image9 from './assets/gallery/rooms/lux4x/lux4x9.jpg'
import Image10 from './assets/gallery/rooms/lux4x/lux4x10.jpg'
import Image11 from './assets/gallery/rooms/lux4x/lux4x11.jpg'
import Image12 from './assets/gallery/rooms/lux4x/lux4x12.jpg'
import Image13 from './assets/gallery/rooms/lux4x/lux4x13.jpg'
import Image14 from './assets/gallery/rooms/lux4x/lux4x14.jpg'
import Image15 from './assets/gallery/rooms/lux4x/lux4x15.jpg'
import Image16 from './assets/gallery/rooms/lux4x/lux4x16.jpg'
import Image17 from './assets/gallery/rooms/lux4x/lux4x17.jpg'
import Image18 from './assets/gallery/rooms/lux4x/lux4x18.jpg'
import Image19 from './assets/gallery/rooms/lux4x/lux4x19.jpg'

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: Image1,
    width: 1000,
    height: 1333
  },
  {
    src: Image2,
    width: 1000,
    height: 1333
  },
  {
    src: Image3,
    width: 1000,
    height: 1333
  },
  {
    src: Image4,
    width: 1000,
    height: 1333
  },
  {
    src: Image5,
    width: 1000,
    height: 1333
  },
  {
    src: Image6,
    width: 1000,
    height: 1333
  },
  {
    src: Image7,
    width: 1000,
    height: 1333
  },
  {
    src: Image8,
    width: 1000,
    height: 1333
  },
  {
    src: Image9,
    width: 1000,
    height: 1333
  },
  {
    src: Image10,
    width: 1000,
    height: 1333
  },
  {
    src: Image11,
    width: 1000,
    height: 1333
  },
  {
    src: Image12,
    width: 1000,
    height: 1333
  },
  {
    src: Image13,
    width: 1000,
    height: 1333
  },
  {
    src: Image14,
    width: 1000,
    height: 1333
  },
  {
    src: Image15,
    width: 1000,
    height: 1333
  },
  {
    src: Image16,
    width: 1000,
    height: 1333
  },
  {
    src: Image17,
    width: 1000,
    height: 1333
  },
  {
    src: Image18,
    width: 1000,
    height: 1333
  },
  {
    src: Image19,
    width: 1000,
    height: 1333
  }
];
