import React, { Component } from 'react'
import {Container} from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Map from "./Map.jsx"

export default class ContactUs extends Component {
	render() {
		return (
			<div>
				<Container className="contactUs">
				      <Row>
				        <Col  sm={6}>
				        	<p><br /></p>
				        	<p>улица Ленина 32А <br />
				        		Алупка, Крым, Россия </p>
				        	<p> +79787619606 <br />
				        		+79117981055 </p>
				        	<p> uzhniy.kedr@gmail.com <br />
				        		nataliya.kebkal@gmail.com </p>
				        	<p> Пн. – Вс. КРУГЛОСУТОЧНО </p>
				        </Col>
				        <Col ><iframe className="map" src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=116261379533" frameborder="0"></iframe></Col>
				      </Row>				      
				    </Container>
			</div>
			// <CarouselBox />
			)
	}
}
