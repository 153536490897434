import { useEffect } from 'react';
import {useLocation} from "react-router";

const MountHandler = ({ onMount, onUnMount, withPathDependency = false }) => {
    const {pathname} = useLocation();
    const deps = withPathDependency ? [pathname] : [];
    useEffect(() => {
        onMount()
        return onUnMount
    }, deps);
    return null
}

export default MountHandler;