import { Image } from "react-grid-gallery";

import Image1 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x1.jpg'
import Image2 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x2.jpg'
import Image3 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x3.jpg'
import Image4 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x4.jpg'
import Image5 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x5.jpg'
import Image6 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x6.jpg'
import Image7 from './assets/gallery/rooms/ulu4wennij4x/ulu4wennij4x7.jpg'

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: Image1,
    width: 1000,
    height: 1333
  },
  {
    src: Image2,
    width: 1000,
    height: 1333
  },
  {
    src: Image3,
    width: 1000,
    height: 1333
  },
  {
    src: Image4,
    width: 1000,
    height: 1333
  },
  {
    src: Image5,
    width: 1000,
    height: 1333
  },
  {
    src: Image6,
    width: 1000,
    height: 1333
  },
  {
    src: Image7,
    width: 1000,
    height: 1333
  }
];
