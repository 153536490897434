import logo from './logo_circle.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header.js"

function App() {
  return (
    <div>
      <Header />
    </div>
  );
}

export default App;
