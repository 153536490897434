import React, { Component } from 'react';
import ContactUs  from "../components/ContactUs.js";
import Footer  from "../components/Footer.js";
import TravellinePluginMain from "../components/TravellinePluginMain";

export default class Contacts extends Component {
	render() {
		return (
			<div>
				<div><TravellinePluginMain /></div>
				<div id="contactUs"><ContactUs /></div>
				<div id="footer"><Footer /></div>
			</div>
			)
	}
}